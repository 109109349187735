import {all, call, put, race, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import * as CActions from '../actions/customer-action';
import {
    CREATE_FAX_BINDING_FAILED,
    CREATE_FAX_BINDING_SUCCESS,
    CREATE_TEAM_USER_FAILED,
    CREATE_TEAM_USER_SUCCESS,
    CREATE_USER_ROLE_FAILED,
    CREATE_USER_ROLE_SUCCESS,
    createCustomerUserRole,
    DELETE_FAX_BINDING_FAILED,
    DELETE_FAX_BINDING_SUCCESS,
    DELETE_TEAM_USER_FAILED,
    DELETE_TEAM_USER_SUCCESS,
    DELETE_USER_ROLE_FAILED,
    DELETE_USER_ROLE_SUCCESS,
    FETCH_FAX_BINDINGS_FAILED,
    FETCH_FAX_BINDINGS_SAGA,
    FETCH_FAX_BINDINGS_SUCCESS,
    fetchCustomerPolicies,
    STORE_FAX_BINDINGS,
    UPDATE_TEAM_USER_FAILED,
    UPDATE_TEAM_USER_SUCCESS,
    UPDATE_USER_ROLE_FAILED,
    UPDATE_USER_ROLE_SUCCESS,
} from '../actions/customer-action';
import * as PActions from '../actions/portal-action';
import {dangerToast, primaryToast, secondaryToast} from '../actions/portal-action';
// noinspection ES6PreferShortImport
import {formatQueryString} from '../../utils/format';
import {genericCreate, genericDelete, genericFetch, genericUpdate} from './generic-sagas';
import {APIRequestGenerator, takeFirst} from './index';
import {
    customerIntegrationApiKeySelector,
    customerIntegrationBindingSelector,
} from '../selectors/customer-selectors';

export function* fetchUsers() {
    yield* genericFetch({
        endpoint: '/users',
        loadingId: 'users',
        storeAction: CActions.STORE_USERS,
        successAction: CActions.FETCH_USERS_SUCCESS,
        failedAction: CActions.FETCH_USERS_FAILED,
    });
}

export function* fetchCustomers() {
    yield* genericFetch({
        endpoint: '/customers',
        loadingId: 'customers',
        storeAction: CActions.STORE_CUSTOMERS,
        successAction: CActions.FETCH_CUSTOMERS_SUCCESS,
        failedAction: CActions.FETCH_CUSTOMERS_FAILED,
    });
}

export function* fetchCustomer(action) {
    yield* genericFetch({
        endpoint: `/customers/${action.payload.id}`,
        loadingId: 'customers',
        storeAction: CActions.STORE_CUSTOMER,
        successAction: CActions.FETCH_CUSTOMER_SUCCESS,
        failedAction: CActions.FETCH_CUSTOMER_FAILED,
    });
}

export function* fetchJourneys(action) {
    yield* genericFetch({
        endpoint: `/journeys?${formatQueryString(action.payload)}`,
        loadingId: 'journeys',
        storeAction: CActions.STORE_JOURNEYS,
        successAction: CActions.FETCH_JOURNEYS_SUCCESS,
        failedAction: CActions.FETCH_JOURNEYS_FAILED,
    });
}

export function* fetchJourney(action) {
    let {id, ...params} = action.payload;
    yield* genericFetch({
        endpoint: `/journeys/${id}?${formatQueryString(params)}`,
        loadingId: `journeys_${id}`,
        storeAction: CActions.STORE_JOURNEY,
        successAction: CActions.FETCH_JOURNEY_SUCCESS,
        failedAction: CActions.FETCH_JOURNEY_FAILED,
    });
}

export function* fetchTeams() {
    yield* genericFetch({
        endpoint: '/teams',
        loadingId: 'teams',
        storeAction: CActions.STORE_TEAMS,
        successAction: CActions.FETCH_TEAMS_SUCCESS,
        failedAction: CActions.FETCH_TEAMS_FAILED,
    });
}

export function* fetchRoles() {
    yield* genericFetch({
        endpoint: '/roles',
        loadingId: 'roles',
        storeAction: CActions.STORE_ROLES,
        successAction: CActions.FETCH_ROLES_SUCCESS,
        failedAction: CActions.FETCH_ROLES_FAILED,
    });
}

export function* fetchResourceTypes() {
    yield genericFetch({
        endpoint: '/resource_types',
        loadingId: 'resource_types',
        storeAction: CActions.STORE_RESOURCE_TYPES,
        successAction: CActions.FETCH_RESOURCE_TYPES_SUCCESS,
        failedAction: CActions.FETCH_RESOURCE_TYPES_FAILED,
    });
}

export function* fetchPolicies(action) {
    const {role_id} = action.payload;
    yield* genericFetch({
        endpoint: `/roles/${role_id}/policies`,
        loadingId: `policies_${role_id}`,
        storeAction: CActions.STORE_POLICIES,
        successAction: CActions.FETCH_POLICIES_SUCCESS,
        failedAction: CActions.FETCH_POLICIES_FAILED,
    });
}

export function* fetchFaxConfigs() {
    yield* genericFetch({
        endpoint: '/faxes/configs',
        loadingId: 'fax_configs',
        storeAction: CActions.STORE_FAX_CONFIGS,
        successAction: CActions.FETCH_FAX_CONFIGS_SUCCESS,
        failedAction: CActions.FETCH_FAX_CONFIGS_FAILED,
    });
}

export function* fetchFaxBindings(action) {
    let {id} = action.payload;
    try {
        yield put({type: PActions.LOADING, payload: `fax_bindings_${id}`});
        let {data} = yield call(APIRequestGenerator, 'get', `/faxes/configs/${id}/bindings`);
        yield put({type: STORE_FAX_BINDINGS, payload: {id, data}});
        yield put({type: FETCH_FAX_BINDINGS_SUCCESS});
    } catch (e) {
        yield put({
            type: FETCH_FAX_BINDINGS_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `fax_bindings_${id}`});
    }
}

export function* fetchSmsConfigs() {
    yield* genericFetch({
        endpoint: '/sms/configs',
        loadingId: 'sms_configs',
        storeAction: CActions.STORE_SMS_CONFIGS,
        successAction: CActions.FETCH_SMS_CONFIGS_SUCCESS,
        failedAction: CActions.FETCH_SMS_CONFIGS_FAILED,
    });
}

export function* fetchSmsBindings(action) {
    const {id} = action.payload;
    try {
        yield put({type: PActions.LOADING, payload: `sms_bindings_${id}`});
        let {data} = yield call(APIRequestGenerator, 'get', `/sms/configs/${id}/bindings`);
        yield put({type: CActions.STORE_SMS_BINDINGS, payload: {id, data}});
        yield put({type: CActions.FETCH_SMS_BINDINGS_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.FETCH_SMS_BINDINGS_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `sms_bindings_${id}`});
    }
}

export function* fetchSmsBandwidthApplication(action) {
    const phone_number = action.payload;
    yield* genericFetch({
        endpoint: `/sms/bandwidth/dashboard/${phone_number}`,
        loadingId: 'sms_bandwidth_dashboard',
        storeAction: CActions.STORE_SMS_BANDWIDTH,
        successAction: CActions.FETCH_SMS_BANDWIDTH_SUCCESS,
        failedAction: CActions.FETCH_SMS_BANDWIDTH_FAILED,
    });
}

export function* fetchSmsBindingsUsers() {
    yield* genericFetch({
        endpoint: `/sms/bindings/acrobits_users`,
        loadingId: 'sms_bindings_users',
        storeAction: CActions.STORE_SMS_BINDINGS_USERS,
        successAction: CActions.FETCH_SMS_BINDINGS_USERS_SUCCESS,
        failedAction: CActions.FETCH_SMS_BINDINGS_USERS_FAILURE,
    });
}

export function* fetchUserRoles() {
    yield* genericFetch({
        endpoint: '/user_roles',
        loadingId: 'user_roles',
        storeAction: CActions.STORE_USER_ROLES,
        successAction: CActions.FETCH_USER_ROLES_SUCCESS,
        failedAction: CActions.FETCH_USER_ROLES_FAILED,
    });
}

export function* fetchTeamUsers() {
    yield* genericFetch({
        endpoint: '/team_users',
        loadingId: 'team_users',
        storeAction: CActions.STORE_TEAM_USERS,
        successAction: CActions.FETCH_TEAM_USERS_SUCCESS,
        failedAction: CActions.FETCH_TEAM_USERS_FAILED,
    });
}

export function* fetchLanguages() {
    yield* genericFetch({
        endpoint: '/languages',
        loadingId: 'languages',
        storeAction: CActions.STORE_LANGUAGES,
        successAction: CActions.FETCH_LANGUAGES_SUCCESS,
        failedAction: CActions.FETCH_LANGUAGES_FAILED,
    });
}

export function* createUser(action) {
    try {
        yield put(secondaryToast({content: 'Creating user...', loading: true}));
        const {role_actions, ...params} = action.payload;
        let {data} = yield call(APIRequestGenerator, 'post', `/users`, {params});

        function* roleAction(role_action) {
            yield put(createCustomerUserRole({user_id: data.id, ...role_action.payload}));
            yield race([
                take([
                    CREATE_USER_ROLE_SUCCESS,
                    UPDATE_USER_ROLE_SUCCESS,
                    DELETE_USER_ROLE_SUCCESS,
                ]),
                take([CREATE_USER_ROLE_FAILED, UPDATE_USER_ROLE_FAILED, DELETE_USER_ROLE_FAILED]),
            ]);
        }

        yield all(role_actions.map(roleAction));
        yield put({type: CActions.CREATE_USER_SUCCESS});
        yield put(primaryToast({content: 'User created'}));
    } catch (e) {
        yield put(dangerToast({content: 'Could not create user'}));
        yield put({
            type: CActions.CREATE_USER_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_USERS_SAGA});
    }
}

export function* resendInviteSaga(action) {
    try {
        yield put(secondaryToast({content: 'Resending invitation...', loading: true}));
        let {userId} = action.payload;
        yield call(APIRequestGenerator, 'post', `/users/${userId}/invitation`);
        yield put(primaryToast({content: 'Invitation sent'}));
        yield put({type: CActions.RESEND_INVITATION_SUCCESS});
    } catch (e) {
        yield put(dangerToast({content: 'Could not resend invitation'}));
        yield put({
            type: CActions.RESEND_INVITATION_FAILED,

            payload: e.message,
        });
    }
}

export function* createCustomer(action) {
    yield* genericCreate({
        endpoint: '/customers',
        params: action.payload,
        fetchAction: CActions.FETCH_CUSTOMERS_SAGA,
        successAction: CActions.CREATE_CUSTOMER_SUCCESS,
        successMessage: 'Customer created',
        failedAction: CActions.CREATE_CUSTOMER_FAILED,
        failedMessage: 'Could not create customer',
        progressMessage: 'Creating customer...',
    });
}

export function* createTeam(action) {
    try {
        yield put(secondaryToast({content: 'Creating team...', loading: true}));
        const {user_actions, ...params} = action.payload;
        let {data} = yield call(APIRequestGenerator, 'post', `/teams`, {params});

        function* userAction(user_action) {
            yield put({team_id: data.id, ...user_action.payload});
            yield race([
                take([
                    CREATE_TEAM_USER_SUCCESS,
                    UPDATE_TEAM_USER_SUCCESS,
                    DELETE_TEAM_USER_SUCCESS,
                ]),
                take([CREATE_TEAM_USER_FAILED, UPDATE_TEAM_USER_FAILED, DELETE_TEAM_USER_FAILED]),
            ]);
        }

        yield all(user_actions.map(userAction));
        yield put({type: CActions.CREATE_TEAM_SUCCESS});
        yield put(primaryToast({content: 'Team created'}));
    } catch (e) {
        yield put(dangerToast({content: 'Could not create team'}));
        yield put({
            type: CActions.CREATE_TEAM_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_TEAMS_SAGA});
    }
}

export function* createRole(action) {
    yield* genericCreate({
        endpoint: '/roles',
        params: action.payload,
        fetchAction: CActions.FETCH_ROLES_SAGA,
        successAction: CActions.CREATE_ROLE_SUCCESS,
        successMessage: 'Role created',
        failedAction: CActions.CREATE_ROLE_FAILED,
        failedMessage: 'Could not create role',
        progressMessage: 'Creating role...',
    });
}

export function* createPolicy(action) {
    const {role_id, ...policy} = action.payload;
    yield* genericCreate({
        endpoint: `/roles/${role_id}/policies`,
        params: policy,
        fetchAction: fetchCustomerPolicies({role_id}),
        successAction: CActions.CREATE_POLICY_SUCCESS,
        successMessage: 'Policy created',
        failedAction: CActions.CREATE_POLICY_FAILED,
        failedMessage: 'Could not create policy',
        progressMessage: 'Creating policy...',
    });
}

export function* createFaxConfig(action) {
    try {
        yield put(secondaryToast({content: 'Creating fax configuration...', loading: true}));
        const {did, binding_actions, ...params} = action.payload;
        yield call(APIRequestGenerator, 'post', `/faxes/configs`, {params: {did, ...params}});
        for (const a of binding_actions) {
            yield put(a);
        }
        yield put(primaryToast({content: 'Fax config created'}));
        yield put({type: CActions.CREATE_FAX_CONFIG_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.CREATE_FAX_CONFIG_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_FAX_CONFIGS_SAGA});
    }
}

export function* createSmsConfig(action) {
    try {
        yield put(secondaryToast({content: 'Creating SMS configuration...', loading: true}));
        const {did, binding_actions, service_type} = action.payload;
        yield call(APIRequestGenerator, 'post', '/sms/configs', {params: {did, service_type}});
        for (const a of binding_actions) {
            yield put(a);
        }
        yield put(primaryToast({content: 'SMS config created'}));
        yield put({type: CActions.CREATE_SMS_CONFIG_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.CREATE_SMS_CONFIG_FAILED,

            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_SMS_CONFIGS_SAGA});
        yield put({type: CActions.FETCH_SMS_BINDINGS_USERS_SAGA});
    }
}

export function* createFaxBinding(action) {
    let {fax_did, ...params} = action.payload;
    try {
        yield call(APIRequestGenerator, 'post', `/faxes/configs/${fax_did}/bindings`, {params});

        yield put({type: CREATE_FAX_BINDING_SUCCESS});
    } catch (e) {
        yield put({type: CREATE_FAX_BINDING_FAILED, payload: e.message});
    }
}

export function* createSmsBinding(action) {
    let {sms_did, ...params} = action.payload;

    try {
        yield call(APIRequestGenerator, 'post', `/sms/configs/${sms_did}/bindings`, {
            params,
        });
        yield put({type: CActions.CREATE_SMS_BINDING_SUCCESS});
    } catch (e) {
        yield put({type: CActions.CREATE_SMS_BINDING_FAILED, payload: e.message});
    }
}

export function* createUserRole(action) {
    yield* genericCreate({
        endpoint: '/user_roles',
        params: action.payload,
        fetchAction: CActions.FETCH_USER_ROLES_SAGA,
        successAction: CActions.CREATE_USER_ROLE_SUCCESS,
        successMessage: null,
        failedAction: CActions.CREATE_USER_ROLE_FAILED,
        failedMessage: null,
    });
}

export function* createTeamUser(action) {
    yield* genericCreate({
        endpoint: '/team_users',
        params: action.payload,
        fetchAction: CActions.FETCH_TEAM_USERS_SAGA,
        successAction: CActions.CREATE_TEAM_USER_SUCCESS,
        successMessage: null,
        failedAction: CActions.CREATE_TEAM_USER_FAILED,
        failedMessage: null,
    });
}

export function* createPbxAdminUsers(action) {
    let {...params} = action.payload;

    try {
        yield put({type: PActions.LOADING, payload: `pbx_admin_users`});
        let data = yield call(APIRequestGenerator, 'post', '/pbx_admin_users', {params: params});
        yield put({type: CActions.CREATE_PBX_ADMIN_USERS_SUCCESS});
        yield put({type: CActions.STORE_PBX_ADMIN_USERS, payload: data});
    } catch (e) {
        yield put({
            type: CActions.CREATE_PBX_ADMIN_USERS_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `pbx_admin_users`});
    }
}

export function* updateUser(action) {
    try {
        yield put(secondaryToast({content: 'Updating user...', loading: true}));
        let {id, role_actions, ...params} = action.payload;
        yield call(APIRequestGenerator, 'put', `/users/${id}`, {params: params});

        function* roleAction(role_action) {
            yield put(role_action);
            yield race([
                take([
                    CREATE_USER_ROLE_SUCCESS,
                    UPDATE_USER_ROLE_SUCCESS,
                    DELETE_USER_ROLE_SUCCESS,
                ]),
                take([CREATE_USER_ROLE_FAILED, UPDATE_USER_ROLE_FAILED, DELETE_USER_ROLE_FAILED]),
            ]);
        }

        yield all(role_actions.map(roleAction));
        yield put({type: CActions.UPDATE_USER_SUCCESS});
        yield put(primaryToast({content: 'User updated'}));
    } catch (e) {
        yield put({
            type: CActions.UPDATE_USER_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_USERS_SAGA});
    }
}

export function* updateCustomer(action) {
    let {id, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/customers/${id}`,
        params: params,
        fetchAction: CActions.FETCH_CUSTOMERS_SAGA,
        successAction: CActions.UPDATE_CUSTOMER_SUCCESS,
        successMessage: 'Customer updated',
        failedAction: CActions.UPDATE_CUSTOMER_FAILED,
        failedMessage: 'Could not update customer',
        progressMessage: 'Updating customer...',
    });
}

export function* updateTeam(action) {
    try {
        yield put(secondaryToast({content: 'Updating team...', loading: true}));
        let {id, user_actions, ...params} = action.payload;
        yield call(APIRequestGenerator, 'put', `/teams/${id}`, {params: params});

        function* userAction(user_action) {
            yield put(user_action);
            yield race([
                take([
                    CREATE_TEAM_USER_SUCCESS,
                    UPDATE_TEAM_USER_SUCCESS,
                    DELETE_TEAM_USER_SUCCESS,
                ]),
                take([CREATE_TEAM_USER_FAILED, UPDATE_TEAM_USER_FAILED, DELETE_TEAM_USER_FAILED]),
            ]);
        }

        yield all(user_actions.map(userAction));
        yield put({type: CActions.UPDATE_TEAM_SUCCESS});
        yield put(primaryToast({content: 'Team updated'}));
    } catch (e) {
        yield put({
            type: CActions.UPDATE_TEAM_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_TEAMS_SAGA});
    }
}

export function* updateRole(action) {
    let {id, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/roles/${id}`,
        params: params,
        fetchAction: CActions.FETCH_ROLES_SAGA,
        successAction: CActions.UPDATE_ROLE_SUCCESS,
        successMessage: 'Role updated',
        failedAction: CActions.UPDATE_ROLE_FAILED,
        failedMessage: 'Could not update role',
        progressMessage: 'Updating role...',
    });
}

export function* updatePolicy(action) {
    const {id, role_id, ...policy} = action.payload;
    yield* genericUpdate({
        endpoint: `/roles/${role_id}/policies/${id}`,
        params: policy,
        fetchAction: fetchCustomerPolicies({role_id}),
        successAction: CActions.UPDATE_POLICY_SUCCESS,
        successMessage: 'Policy updated',
        failedAction: CActions.UPDATE_POLICY_FAILED,
        failedMessage: 'Could not update policy',
        progressMessage: 'Updating policy...',
    });
}

export function* updateFaxConfig(action) {
    try {
        yield put(secondaryToast({content: 'Updating fax configuration...', loading: true}));
        let {did, binding_actions, ...params} = action.payload;
        yield call(APIRequestGenerator, 'put', `/faxes/configs/${did}`, {params: params});
        for (const binding_action of binding_actions) {
            yield put(binding_action);
        }
        yield put({type: CActions.UPDATE_FAX_CONFIG_SUCCESS});
        yield put(primaryToast({content: 'Fax config updated'}));
    } catch (e) {
        yield put({
            type: CActions.UPDATE_FAX_CONFIG_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: CActions.FETCH_FAX_CONFIGS_SAGA});
    }
}

export function* updateSmsConfig(action) {
    try {
        yield put(secondaryToast({content: 'Updating SMS configuration... ', loading: true}));
        let {did, binding_actions, ...params} = action.payload;
        yield call(APIRequestGenerator, 'put', `/sms/config/${did}`, {params: params});
        for (const a of binding_actions) {
            yield put(a);
        }
        yield put({type: CActions.UPDATE_SMS_CONFIG_SUCCESS});
        yield put(primaryToast({content: 'SMS config updated'}));
    } catch (e) {
        yield put({type: CActions.UPDATE_SMS_CONFIG_FAILED, payload: e.message});
    } finally {
        yield put({type: CActions.FETCH_SMS_CONFIGS_SAGA});
        yield put({type: CActions.FETCH_SMS_BINDINGS_USERS_SAGA});
    }
}

export function* updateFaxBinding(action) {
    let {id, fax_did, ...params} = action.payload;
    try {
        yield call(APIRequestGenerator, 'put', `/faxes/configs/${fax_did}/bindings/${id}`, {
            params,
        });
        yield put({type: CActions.UPDATE_FAX_BINDING_SUCCESS});
    } catch (e) {
        yield put({type: CActions.UPDATE_FAX_BINDING_FAILED, payload: e.message});
    } finally {
        yield put({type: FETCH_FAX_BINDINGS_SAGA, payload: {id}});
    }
}

export function* updateSmsBinding(action) {
    let {id, sms_did, ...params} = action.payload;
    try {
        yield call(APIRequestGenerator, 'put', `/sms/configs/${sms_did}/bindings/${id}`, {
            params: params,
        });
        yield put({type: CActions.UPDATE_SMS_BINDING_SUCCESS});
    } catch (e) {
        yield put({type: CActions.UPDATE_SMS_BINDING_FAILED, payload: e.message});
    }
}

export function* updateUserRole(action) {
    let {id, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/user_roles/${id}`,
        params: params,
        fetchAction: CActions.FETCH_USER_ROLES_SAGA,
        successAction: CActions.UPDATE_USER_ROLE_SUCCESS,
        successMessage: null,
        failedAction: CActions.UPDATE_USER_ROLE_FAILED,
        failedMessage: null,
    });
}

export function* updateTeamUser(action) {
    let {id, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/team_users/${id}`,
        params: params,
        fetchAction: CActions.FETCH_TEAM_USERS_SAGA,
        successAction: CActions.UPDATE_TEAM_USER_SUCCESS,
        successMessage: null,
        failedAction: CActions.UPDATE_TEAM_USER_FAILED,
        failedMessage: null,
    });
}

export function* deleteUser(action) {
    let {id, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/users/${id}`,
        params: params,
        fetchAction: CActions.FETCH_USERS_SAGA,
        successAction: CActions.DELETE_USER_SUCCESS,
        successMessage: 'User deleted',
        failedAction: CActions.DELETE_USER_FAILED,
        failedMessage: 'Could not delete user',
        progressMessage: 'Deleting user...',
    });
}

export function* deleteTeam(action) {
    let {id, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/teams/${id}`,
        params: params,
        fetchAction: CActions.FETCH_TEAMS_SAGA,
        successAction: CActions.DELETE_TEAM_SUCCESS,
        successMessage: 'Team deleted',
        failedAction: CActions.DELETE_TEAM_FAILED,
        failedMessage: 'Could not delete team',
        progressMessage: 'Deleting team...',
    });
}

export function* deleteRole(action) {
    let {id, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/roles/${id}`,
        params: params,
        fetchAction: CActions.FETCH_ROLES_SAGA,
        successAction: CActions.DELETE_ROLE_SUCCESS,
        successMessage: 'Role deleted',
        failedAction: CActions.DELETE_ROLE_FAILED,
        failedMessage: 'Could not delete role',
        progressMessage: 'Deleting role...',
    });
}

export function* deletePolicy(action) {
    const {id, role_id} = action.payload;
    yield* genericDelete({
        endpoint: `/roles/${role_id}/policies/${id}`,
        params: fetchCustomerPolicies({role_id}),
        fetchAction: {},
        successAction: CActions.DELETE_POLICY_SUCCESS,
        successMessage: 'Policy deleted',
        failedAction: CActions.DELETE_POLICY_FAILED,
        failedMessage: 'Could not delete policy',
        progressMessage: 'Deleting policy...',
    });
}

export function* deleteFaxConfig(action) {
    let {did, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/faxes/configs/${did}`,
        params: params,
        fetchAction: CActions.FETCH_FAX_CONFIGS_SAGA,
        successAction: CActions.DELETE_FAX_CONFIG_SUCCESS,
        successMessage: 'Fax number deleted',
        failedAction: CActions.DELETE_FAX_CONFIG_FAILED,
        failedMessage: 'Could not delete fax number',
        progressMessage: 'Deleting fax configuration...',
    });
}

export function* deleteSmsConfig(action) {
    let {did, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/sms/config/${did}`,
        params: params,
        fetchAction: CActions.FETCH_SMS_CONFIGS_SAGA,
        successAction: CActions.DELETE_SMS_CONFIG_SUCCESS,
        successMessage: 'SMS number deleted',
        failedAction: CActions.DELETE_SMS_CONFIG_FAILED,
        failedMessage: 'Could not delete SMS number',
        progressMessage: 'Deleting SMS configuration...',
    });
}

export function* deleteFaxBinding(action) {
    let {id, fax_did} = action.payload;
    try {
        yield call(APIRequestGenerator, 'del', `/faxes/configs/${fax_did}/bindings/${id}`);
        yield put({type: DELETE_FAX_BINDING_SUCCESS});
        yield put(primaryToast({content: 'Fax binding deleted'}));
    } catch (e) {
        yield put({type: DELETE_FAX_BINDING_FAILED, payload: e.message});
        yield put(dangerToast({content: 'Could not delete fax binding'}));
    } finally {
        yield put({type: FETCH_FAX_BINDINGS_SAGA, payload: {id}});
    }
}

export function* deleteSmsBinding(action) {
    let {id, sms_did} = action.payload;
    try {
        yield call(APIRequestGenerator, 'del', `/sms/configs/${sms_did}/bindings/${id}`);
        yield put({type: CActions.DELETE_SMS_BINDING_SUCCESS});
        yield put(primaryToast({content: 'SMS binding deleted'}));
    } catch (e) {
        yield put({type: CActions.DELETE_SMS_BINDING_FAILED, payload: e.message});
        yield put(dangerToast({content: 'Could not delete SMS binding'}));
    } finally {
        yield put({type: CActions.FETCH_SMS_BINDINGS_SAGA, payload: {id}});
    }
}

export function* deleteUserRole(action) {
    let {id, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/user_roles/${id}`,
        params: params,
        fetchAction: CActions.FETCH_USER_ROLES_SAGA,
        successAction: CActions.DELETE_USER_ROLE_SUCCESS,
        successMessage: null,
        failedAction: CActions.DELETE_USER_ROLE_FAILED,
        failedMessage: null,
    });
}

export function* deleteTeamUser(action) {
    let {id, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/team_users/${id}`,
        params: params,
        fetchAction: CActions.FETCH_TEAM_USERS_SAGA,
        successAction: CActions.DELETE_TEAM_USER_SUCCESS,
        successMessage: null,
        failedAction: CActions.DELETE_TEAM_USER_FAILED,
        failedMessage: null,
    });
}

function* fetchIntegrationAccess(action) {
    try {
        yield put({type: PActions.LOADING, payload: `${action.payload}_access`});
        let {data} = yield call(APIRequestGenerator, 'get', `/integrations/${action.payload}`);
        yield put({type: CActions.STORE_INTEGRATION_ACCESS, payload: {name: action.payload, data}});
        yield put({type: CActions.FETCH_INTEGRATION_ACCESS_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.FETCH_INTEGRATION_ACCESS_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `${action.payload}_access`});
    }
}

function* fetchIntegrationUsers(action) {
    try {
        yield put({type: PActions.LOADING, payload: `${action.payload}_users`});
        let {data: users} = yield call(
            APIRequestGenerator,
            'get',
            `/integrations/${action.payload}/users`
        );
        yield put({
            type: CActions.STORE_INTEGRATION_USERS,
            payload: {name: action.payload, data: users},
        });
        let {data: bindings} = yield call(
            APIRequestGenerator,
            'get',
            `/integrations/${action.payload}/bindings`
        );
        yield put({
            type: CActions.STORE_INTEGRATION_BINDING,
            payload: {name: action.payload, data: bindings},
        });
        yield put({type: CActions.FETCH_INTEGRATION_USERS_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.FETCH_INTEGRATION_USERS_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `${action.payload}_users`});
    }
}

function* updateIntegrationBinding(action) {
    yield put(secondaryToast({content: 'Updating binding...', loading: true}));
    yield put({type: PActions.LOADING, payload: `${action.payload}_binding`});
    let {extension, value, name} = action.payload;
    try {
        let bindings = yield select(customerIntegrationBindingSelector(name));
        let apikey = yield select(customerIntegrationApiKeySelector(name));
        const user = value;
        const extension_to_remove = Object.keys(bindings).find(
            (extension) => bindings[extension] === user
        );
        if (user) {
            if (extension_to_remove) {
                yield call(APIRequestGenerator, 'del', `/integrations/${name}/bindings/${user}`, {
                    params: {apikey: apikey, extension: extension_to_remove},
                });
                delete bindings[extension_to_remove];
            }
            const params = {extension: Number(extension), apikey, user};
            yield call(APIRequestGenerator, 'post', `/integrations/${name}/bindings`, {params});
            bindings.extension = user;
        }
        yield put({type: CActions.UPDATE_INTEGRATION_BINDING_SUCCESS, payload: bindings});
    } catch (e) {
        yield put({
            type: CActions.UPDATE_INTEGRATION_BINDING_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `${action.payload}_binding`});
        yield put({type: CActions.FETCH_INTEGRATION_USERS_SAGA, payload: name});
    }
}

function* deleteIntegrationBinding(action) {
    yield put(secondaryToast({content: 'Deleting binding...', loading: true}));
    yield put({type: PActions.LOADING, payload: `${action.payload}_binding`});
    let {extension, value, name} = action.payload;
    try {
        let apikey = yield select(customerIntegrationApiKeySelector(name));
        yield call(APIRequestGenerator, 'del', `/integrations/${name}/bindings/${value}`, {
            params: {apikey, extension},
        });
        yield put({type: CActions.DELETE_INTEGRATION_BINDING_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.DELETE_INTEGRATION_BINDING_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: `${action.payload}_binding`});
        yield put({type: CActions.FETCH_INTEGRATION_USERS_SAGA, payload: name});
    }
}

export function* deleteIntegration(action) {
    yield put(secondaryToast({content: 'Deleting integration...', loading: true}));
    let apikey = yield select(customerIntegrationApiKeySelector(action.payload));
    let params = {apikey};
    try {
        yield call(APIRequestGenerator, 'del', `/integrations/${action.payload}`, {params});
        yield put({type: CActions.DELETE_INTEGRATION_SUCCESS});
        yield put(primaryToast({content: 'Integration deleted'}));
    } catch (e) {
        yield put({
            type: CActions.DELETE_INTEGRATION_FAILED,
            payload: e.message,
        });
        yield put(dangerToast({content: 'Integration could not be deleted'}));
    }
}

export function* fetchApiKeys(action) {
    yield* genericFetch({
        endpoint: `/api_keys?${formatQueryString(action.payload)}`,
        loadingId: 'api_keys',
        storeAction: CActions.STORE_API_KEYS,
        successAction: CActions.FETCH_API_KEYS_SUCCESS,
        failedAction: CActions.FETCH_API_KEYS_FAILED,
    });
}

export function* createApiKey(action) {
    yield* genericCreate({
        endpoint: '/api_keys',
        params: action.payload,
        fetchAction: CActions.FETCH_API_KEYS_SAGA,
        successAction: CActions.CREATE_API_KEY_SUCCESS,
        successMessage: 'API key created',
        failedAction: CActions.CREATE_API_KEY_FAILED,
        failedMessage: 'Could not create API key',
        progressMessage: 'Creating API key...',
    });
}

export function* updateApiKey(action) {
    let {apikey, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/api_keys/${apikey}`,
        params: params,
        fetchAction: CActions.FETCH_API_KEYS_SAGA,
        successAction: CActions.UPDATE_API_KEY_SUCCESS,
        successMessage: 'API key updated',
        failedAction: CActions.UPDATE_API_KEY_FAILED,
        failedMessage: 'Could not update API key',
        progressMessage: 'Updating API key...',
    });
}

export function* deleteApiKey(action) {
    let {apikey, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/api_keys/${apikey}`,
        params: params,
        fetchAction: CActions.FETCH_API_KEYS_SAGA,
        successAction: CActions.DELETE_API_KEY_SUCCESS,
        successMessage: 'API key deleted',
        failedAction: CActions.DELETE_API_KEY_FAILED,
        failedMessage: 'Could not delete API key',
        progressMessage: 'Deleting API key...',
    });
}

export function* fetchQueues(action) {
    yield* genericFetch({
        endpoint: `/queues?include_system_queues=${action.payload.include_system_queues}`,
        loadingId: 'queues',
        storeAction: CActions.STORE_QUEUES,
        successAction: CActions.FETCH_QUEUES_SUCCESS,
        failedAction: CActions.FETCH_QUEUES_FAILED,
    });
}

export function* fetchAgents(action) {
    yield* genericFetch({
        endpoint: `/agents?include_system_agents=${action.payload.include_system_agents}`,
        loadingId: 'agents',
        storeAction: CActions.STORE_AGENTS,
        successAction: CActions.FETCH_AGENTS_SUCCESS,
        failedAction: CActions.FETCH_AGENTS_FAILED,
    });
}

export function* fetchQueueEventsStatistics(action) {
    const {name, ...params} = action.payload;
    try {
        yield put({type: PActions.LOADING, payload: 'queue_event_statistics'});
        let {data} = yield call(APIRequestGenerator, 'post', `/queue_events/statistics`, {params});
        yield put({type: CActions.STORE_QUEUE_EVENTS_STATISTICS, payload: {name, data}});
        yield put({type: CActions.FETCH_QUEUE_EVENTS_STATISTICS_SUCCESS});
    } catch (e) {
        yield put({
            type: CActions.FETCH_QUEUE_EVENTS_STATISTICS_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'queue_event_statistics'});
    }
}

export function* fetchExtensions() {
    yield* genericFetch({
        endpoint: '/extensions',
        loadingId: 'extensions',
        storeAction: CActions.STORE_EXTENSIONS,
        successAction: CActions.FETCH_EXTENSIONS_SUCCESS,
        failedAction: CActions.FETCH_EXTENSIONS_FAILED,
        version: 'v2',
    });
}

export function* fetchExtensionSettings(action) {
    try {
        let {extension} = action.payload;
        yield put({type: PActions.LOADING, payload: 'extension_settings'});
        let {data} = yield call(APIRequestGenerator, 'get', `/extensions/${extension}`, {
            version: 'v2',
        });
        yield put({type: CActions.STORE_EXTENSION_SETTINGS_SAGA, payload: data});
        yield put({type: CActions.FETCH_EXTENSION_SETTINGS_SUCCESS});
    } catch (e) {
        yield put({type: CActions.FETCH_EXTENSION_SETTINGS_FAILED, payload: e.message});
        yield put({type: CActions.STORE_EXTENSION_SETTINGS_SAGA, payload: {}});
    } finally {
        yield put({type: PActions.LOADED, payload: 'extension_settings'});
    }
}

export function* createExtension(action) {
    try {
        yield put(secondaryToast({content: 'Creating extension...', loading: true}));
        yield call(APIRequestGenerator, 'post', '/extensions', {
            params: action.payload,
            version: 'v2',
        });
        yield put({type: CActions.CREATE_EXTENSION_SUCCESS});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Extension created'}});
        yield put({
            type: CActions.FETCH_EXTENSION_SETTINGS_SAGA,
            payload: {extension: action.payload.extension},
        });
    } catch (e) {
        yield put({
            type: CActions.CREATE_EXTENSION_FAILED,
            payload: e.message,
        });
        yield put({
            type: PActions.STORE_DANGER_TOAST,
            payload: {content: 'Extension could not be created'},
        });
    } finally {
        yield put({type: CActions.FETCH_EXTENSIONS_SAGA});
    }
}

export function* updateExtension(action) {
    try {
        yield put(secondaryToast({content: 'Updating extension...', loading: true}));
        let {extension, ...params} = action.payload;
        yield call(APIRequestGenerator, 'put', `/extensions/${extension}`, {params, version: 'v2'});
        yield put({type: CActions.UPDATE_EXTENSION_SUCCESS});
        yield put({type: CActions.FETCH_EXTENSIONS_SAGA});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Extension updated'}});
        yield put({type: CActions.FETCH_EXTENSION_SETTINGS_SAGA, payload: {extension}});
    } catch (e) {
        yield put({
            type: CActions.UPDATE_EXTENSION_FAILED,
            payload: e.message,
        });
        yield put({
            type: PActions.STORE_DANGER_TOAST,
            payload: {content: 'Extension could not be updated'},
        });
    }
}

export function* updateSipExtension(action) {
    try {
        yield put(secondaryToast({content: 'Updating sip extension...', loading: true}));
        const {extension, ...params} = action.payload;
        yield call(APIRequestGenerator, 'put', `/extensions/${extension}`, {params, version: 'v2'});
        yield put({type: CActions.UPDATE_EXTENSION_SUCCESS});
        yield put({type: CActions.FETCH_EXTENSIONS_SAGA});
        yield put({
            type: PActions.STORE_PRIMARY_TOAST,
            payload: {content: 'Sip extension updated'},
        });
        yield put({type: CActions.FETCH_EXTENSION_SETTINGS_SAGA, payload: {extension}});
    } catch (e) {
        yield put({
            type: CActions.UPDATE_SIP_EXTENSION_FAILED,
            payload: e.message,
        });
        yield put({
            type: PActions.STORE_DANGER_TOAST,
            payload: {content: 'Sip extension could not be updated'},
        });
    }
}

export function* deleteExtension(action) {
    try {
        yield put(secondaryToast({content: 'Deleting extension...', loading: true}));
        yield call(APIRequestGenerator, 'del', `/extensions/${action.payload}`, {version: 'v2'});
        yield put({type: CActions.DELETE_EXTENSION_SUCCESS});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Extension deleted'}});
        yield put({type: CActions.STORE_EXTENSION_SETTINGS_SAGA, payload: {}});
    } catch (e) {
        yield put({
            type: CActions.DELETE_EXTENSION_FAILED,
            payload: e.message,
        });
        yield put({
            type: PActions.STORE_DANGER_TOAST,
            payload: {content: 'Extension could not be deleted'},
        });
    } finally {
        yield put({type: CActions.FETCH_EXTENSIONS_SAGA});
    }
}

export function* fetchIncoming() {
    try {
        let data = yield call(APIRequestGenerator, 'get', '/sms/incoming_numbers');
        yield put({type: CActions.STORE_INCOMING, payload: data});
        yield put({type: CActions.FETCH_INCOMING_SUCCESS});
    } catch (e) {
        yield put({type: CActions.FETCH_INCOMING_FAILED, payload: e.message});
    }
}

export function* fetchProActivation(action) {
    try {
        let customer_id = action.payload.user.customer.id;
        yield put({type: CActions.STORE_USER_PRO, payload: 'trying'});
        let dataAPI = yield call(APIRequestGenerator, 'get', `/talk_instances/${customer_id}`);
        let exist = false;
        if (dataAPI.data.length > 0) {
            exist = true;
        }
        if (exist === false) {
            yield put({type: CActions.FETCH_USER_PRO_SUCCESS, payload: 'create'});
            yield put({type: CActions.CREATE_USER_PRO, payload: 'create'});
        } else {
            yield put({type: CActions.FETCH_USER_PRO_SUCCESS, payload: 'exist'});
        }
    } catch (e) {
        yield put({type: CActions.FETCH_USER_PRO_FAILED, payload: e.message});
    }
}

export function* createProActivation(action) {
    try {
        if (action.payload.status === 'activate') {
            let customer_id = action.payload.user.customer.id;
            let customerObj = {
                customer_id: customer_id,
                status: 'pending',
            };
            yield call(APIRequestGenerator, 'post', `/talk_instances/instances`, {
                params: customerObj,
            });
            yield put({type: CActions.CREATE_USER_PRO, payload: 'activated'});
        }
    } catch (e) {
        yield put({type: CActions.CREATE_USER_PRO_FAILED, payload: e.message});
    }
}

export function* fetchPowerBrokerApiKey(action) {
    try {
        yield put({type: PActions.LOADING, payload: 'power_broker_api_key'});
        let {data} = yield call(
            APIRequestGenerator,
            'get',
            '/integration/power_broker',
            action.payload.customer_id
        );
        yield put({type: CActions.STORE_POWER_BROKER_API_KEY, payload: data.apikey});
    } catch (e) {
        yield put({
            type: CActions.FETCH_POWER_BROKER_API_KEY_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'power_broker_api_key'});
    }
}

export function* createPowerBrokerApiKey(action) {
    try {
        yield put({type: PActions.LOADING, payload: 'power_broker_api_key'});
        let {data} = yield call(
            APIRequestGenerator,
            'post',
            '/integration/power_broker',
            action.payload.customer_id
        );
        yield put({type: CActions.STORE_POWER_BROKER_API_KEY, payload: data.apikey});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Success'}});
    } catch (e) {
        yield put({
            type: CActions.CREATE_POWER_BROKER_API_KEY_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'power_broker_api_key'});
    }
}

export function* deletePowerBrokerApiKey(action) {
    try {
        yield put({type: PActions.LOADING, payload: 'power_broker_api_key'});
        yield call(
            APIRequestGenerator,
            'del',
            '/integration/power_broker',
            action.payload.customer_id
        );
        yield put({type: CActions.STORE_POWER_BROKER_API_KEY, payload: ''});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Success'}});
    } catch (e) {
        yield put({
            type: CActions.DELETE_POWER_BROKER_API_KEY_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'power_broker_api_key'});
    }
}

export function* fetchDeviceProvisioningConfigurations(action) {
    yield* genericFetch({
        endpoint: `/provisioning/devices?${formatQueryString(action.payload)}`,
        loadingId: 'provisioning_configurations',
        storeAction: CActions.STORE_POLYCOM_DEVICE_CONFIGURATIONS,
        successAction: CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SUCCESS,
        failedAction: CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_FAILED,
    });
}

export function* createDeviceProvisioningConfiguration(action) {
    yield* genericCreate({
        endpoint: '/provisioning/devices',
        params: action.payload,
        fetchAction: CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA,
        successAction: CActions.CREATE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS,
        successMessage: 'Device configuration created',
        failedAction: CActions.CREATE_POLYCOM_DEVICE_CONFIGURATION_FAILED,
        failedMessage: 'Could not create device configuration',
        progressMessage: 'Creating device configuration...',
    });
}

export function* updateDeviceProvisioningConfiguration(action) {
    let {mac_address, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/provisioning/devices/${mac_address}`,
        params: params,
        fetchAction: CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA,
        successAction: CActions.UPDATE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS,
        successMessage: 'Device configuration updated',
        failedAction: CActions.UPDATE_POLYCOM_DEVICE_CONFIGURATION_FAILED,
        failedMessage: 'Could not update device configuration',
        progressMessage: 'Updating device configuration...',
    });
}

export function* deleteDeviceProvisioningConfiguration(action) {
    let {mac_address, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/provisioning/devices/${mac_address}`,
        params: params,
        fetchAction: CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA,
        successAction: CActions.DELETE_POLYCOM_DEVICE_CONFIGURATION_SUCCESS,
        successMessage: 'Device configuration deleted',
        failedAction: CActions.DELETE_POLYCOM_DEVICE_CONFIGURATION_FAILED,
        failedMessage: 'Could not delete device configuration',
        progressMessage: 'Deleting device configuration ...',
    });
}

export function* fetchProvisioningConfigurationTemplates(action) {
    yield* genericFetch({
        endpoint: `/provisioning/templates?${formatQueryString(action.payload)}`,
        loadingId: 'configuration_templates',
        storeAction: CActions.STORE_POLYCOM_CONFIGURATION_TEMPLATES,
        successAction: CActions.FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SUCCESS,
        failedAction: CActions.FETCH_POLYCOM_CONFIGURATION_TEMPLATES_FAILED,
    });
}

export function* createProvisioningConfigurationTemplate(action) {
    yield* genericCreate({
        endpoint: '/provisioning/templates',
        params: action.payload,
        fetchAction: CActions.FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA,
        successAction: CActions.CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS,
        successMessage: 'Configuration template created',
        failedAction: CActions.CREATE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED,
        failedMessage: 'Could not create configuration template',
        progressMessage: 'Creating configuration template...',
    });
}

export function* updateProvisioningConfigurationTemplate(action) {
    let {name, ...params} = action.payload;
    yield* genericUpdate({
        endpoint: `/provisioning/templates/${name}`,
        params: params,
        fetchAction: CActions.FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA,
        successAction: CActions.UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS,
        successMessage: 'Configuration template updated',
        failedAction: CActions.UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED,
        failedMessage: 'Could not update configuration template',
        progressMessage: 'Updating configuration template...',
    });
}

export function* deleteProvisioningConfigurationTemplate(action) {
    let {name, ...params} = action.payload;
    yield* genericDelete({
        endpoint: `/provisioning/templates/${name}`,
        params: params,
        fetchAction: CActions.FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA,
        successAction: CActions.DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SUCCESS,
        successMessage: 'Configuration template deleted',
        failedAction: CActions.DELETE_POLYCOM_CONFIGURATION_TEMPLATE_FAILED,
        failedMessage: 'Could not delete configuration template',
        progressMessage: 'Deleting configuration template...',
    });
}

export function* createProvisioningReload(action) {
    let {...params} = action.payload;
    yield* genericCreate({
        endpoint: '/provisioning/reload',
        params: params,
        fetchAction: CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA,
        successAction: CActions.CREATE_POLYCOM_RELOAD_SUCCESS,
        successMessage: 'Devices refreshed',
        failedAction: CActions.CREATE_POLYCOM_RELOAD_FAILED,
        failedMessage: 'Could not refresh devices',
        progressMessage: 'Refreshing devices...',
    });
}

export function* fetchPhoneNumbers() {
    yield genericFetch({
        endpoint: '/dids',
        loadingId: 'phone_numbers',
        storeAction: CActions.STORE_CUSTOMER_PHONE_NUMBERS,
        successAction: CActions.FETCH_CUSTOMER_PHONE_NUMBERS_SUCCESS,
        failedAction: CActions.FETCH_CUSTOMER_PHONE_NUMBERS_FAILED,
    });
}

export function* createCustormerPhoneNumber(action) {
    const {params} = action.payload;
    try {
        yield put(secondaryToast({content: 'Creating phone number...', loading: true}));
        yield put({type: PActions.LOADING, payload: 'create_customer_phone_number'});
        yield call(APIRequestGenerator, 'post', '/dids', {params});
        yield put({type: CActions.CREATE_CUSTOMER_PHONE_NUMBER_SUCCESS});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Phone number created'}});
        yield put({type: CActions.FETCH_CUSTOMER_PHONE_NUMBERS_SAGA});
    } catch (e) {
        yield put({
            type: CActions.CREATE_CUSTOMER_PHONE_NUMBER_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'create_customer_phone_number'});
    }
}

export function* updateCustormerPhoneNumber(action) {
    const {params, didNumber} = action.payload;
    try {
        yield put(secondaryToast({content: 'Updating phone number...', loading: true}));
        yield put({type: PActions.LOADING, payload: 'update_customer_phone_number'});
        yield call(APIRequestGenerator, 'put', `/dids/${didNumber}/number`, {
            params,
        });
        yield put({type: CActions.UPDATE_CUSTOMER_PHONE_NUMBER_SUCCESS});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Phone number updated'}});
        yield put({type: CActions.FETCH_CUSTOMER_PHONE_NUMBERS_SAGA});
    } catch (e) {
        yield put({
            type: CActions.UPDATE_CUSTOMER_PHONE_NUMBER_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'update_customer_phone_number'});
    }
}

export function* deleteCustormerPhoneNumber(action) {
    const {did_number} = action.payload;
    try {
        yield put(secondaryToast({content: 'Deleting phone number...', loading: true}));
        yield put({type: PActions.LOADING, payload: 'delete_customer_phone_number'});
        yield call(APIRequestGenerator, 'del', `/dids/${did_number}/number`);
        yield put({type: CActions.DELETE_CUSTOMER_PHONE_NUMBER_SUCCESS});
        yield put({type: PActions.STORE_PRIMARY_TOAST, payload: {content: 'Phone number deleted'}});
        yield put({type: CActions.FETCH_CUSTOMER_PHONE_NUMBERS_SAGA});
    } catch (e) {
        yield put({
            type: CActions.DELETE_CUSTOMER_PHONE_NUMBER_FAILED,
            payload: e.message,
        });
    } finally {
        yield put({type: PActions.LOADED, payload: 'delete_customer_phone_number'});
    }
}

export function* fetchProvidersList() {
    yield* genericFetch({
        endpoint: '/providers_list',
        loadingId: 'providers_list',
        storeAction: CActions.STORE_PROVIDERS_LIST,
        successAction: CActions.FETCH_PROVIDERS_SUCCESS,
        failedAction: CActions.FETCH_PROVIDERS_LIST_FAILED,
    });
}

export function* fetchCustomerCDRProxy(action) {
    try {
        let start_date = action.payload.dateRange[0];
        let end_date = action.payload.dateRange[1];
        yield put({type: PActions.LOADING, payload: 'cdr_list'});

        let {data} = yield call(
            APIRequestGenerator,
            'get',
            `/billings/cdr?start_date=${start_date}&end_date=${end_date}`
        );

        yield put({type: CActions.STORE_CUSTOMER_CDR_PROXY, payload: data});
    } catch (e) {
        yield put({type: CActions.FETCH_USER_PRO_FAILED, payload: e.message});
    } finally {
        yield put({type: PActions.LOADED, payload: 'cdr_list'});
    }
}

const customerSagas = [
    takeLatest(CActions.FETCH_CUSTOMERS_SAGA, fetchCustomers),
    takeLatest(CActions.FETCH_CUSTOMER_SAGA, fetchCustomer),
    takeEvery(CActions.CREATE_CUSTOMER_SAGA, createCustomer),
    takeEvery(CActions.UPDATE_CUSTOMER_SAGA, updateCustomer),
    takeLatest(CActions.FETCH_JOURNEYS_SAGA, fetchJourneys),
    takeLatest(CActions.FETCH_JOURNEY_SAGA, fetchJourney),
    takeLatest(CActions.FETCH_USERS_SAGA, fetchUsers),
    takeEvery(CActions.CREATE_USER_SAGA, createUser),
    takeEvery(CActions.UPDATE_USER_SAGA, updateUser),
    takeEvery(CActions.DELETE_USER_SAGA, deleteUser),
    takeEvery(CActions.RESEND_INVITATION_SAGA, resendInviteSaga),
    takeLatest(CActions.FETCH_EXTENSIONS_SAGA, fetchExtensions),
    takeLatest(CActions.FETCH_EXTENSION_SETTINGS_SAGA, fetchExtensionSettings),
    takeEvery(CActions.CREATE_EXTENSION_SAGA, createExtension),
    takeEvery(CActions.UPDATE_SIP_EXTENSION_SAGA, updateSipExtension),
    takeEvery(CActions.UPDATE_EXTENSION_SAGA, updateExtension),
    takeEvery(CActions.DELETE_EXTENSION_SAGA, deleteExtension),
    takeLatest(CActions.FETCH_RESOURCE_TYPES_SAGA, fetchResourceTypes),
    takeLatest(CActions.FETCH_TEAMS_SAGA, fetchTeams),
    takeEvery(CActions.CREATE_TEAM_SAGA, createTeam),
    takeEvery(CActions.UPDATE_TEAM_SAGA, updateTeam),
    takeEvery(CActions.DELETE_TEAM_SAGA, deleteTeam),
    takeLatest(CActions.FETCH_ROLES_SAGA, fetchRoles),
    takeEvery(CActions.CREATE_ROLE_SAGA, createRole),
    takeEvery(CActions.UPDATE_ROLE_SAGA, updateRole),
    takeEvery(CActions.DELETE_ROLE_SAGA, deleteRole),
    takeFirst(CActions.FETCH_POLICIES_SAGA, fetchPolicies),
    takeEvery(CActions.CREATE_POLICY_SAGA, createPolicy),
    takeEvery(CActions.UPDATE_POLICY_SAGA, updatePolicy),
    takeEvery(CActions.DELETE_POLICY_SAGA, deletePolicy),
    takeLatest(CActions.FETCH_FAX_CONFIGS_SAGA, fetchFaxConfigs),
    takeEvery(CActions.CREATE_FAX_CONFIG_SAGA, createFaxConfig),
    takeEvery(CActions.UPDATE_FAX_CONFIG_SAGA, updateFaxConfig),
    takeEvery(CActions.DELETE_FAX_CONFIG_SAGA, deleteFaxConfig),
    takeLatest(CActions.FETCH_FAX_BINDINGS_SAGA, fetchFaxBindings),
    takeEvery(CActions.CREATE_FAX_BINDING_SAGA, createFaxBinding),
    takeEvery(CActions.UPDATE_FAX_BINDING_SAGA, updateFaxBinding),
    takeEvery(CActions.DELETE_FAX_BINDING_SAGA, deleteFaxBinding),
    takeLatest(CActions.FETCH_SMS_CONFIGS_SAGA, fetchSmsConfigs),
    takeLatest(CActions.CREATE_SMS_CONFIG_SAGA, createSmsConfig),
    takeLatest(CActions.DELETE_SMS_CONFIG_SAGA, deleteSmsConfig),
    takeLatest(CActions.UPDATE_SMS_CONFIG_SAGA, updateSmsConfig),
    takeLatest(CActions.FETCH_SMS_BINDINGS_SAGA, fetchSmsBindings),
    takeLatest(CActions.CREATE_SMS_BINDING_SAGA, createSmsBinding),
    takeLatest(CActions.DELETE_SMS_BINDING_SAGA, deleteSmsBinding),
    takeLatest(CActions.UPDATE_SMS_BINDING_SAGA, updateSmsBinding),
    takeLatest(CActions.FETCH_SMS_BANDWIDTH_SAGA, fetchSmsBandwidthApplication),
    takeEvery(CActions.CREATE_USER_ROLE_SAGA, createUserRole),
    takeEvery(CActions.UPDATE_USER_ROLE_SAGA, updateUserRole),
    takeEvery(CActions.DELETE_USER_ROLE_SAGA, deleteUserRole),
    takeEvery(CActions.CREATE_TEAM_USER_SAGA, createTeamUser),
    takeEvery(CActions.UPDATE_TEAM_USER_SAGA, updateTeamUser),
    takeEvery(CActions.DELETE_TEAM_USER_SAGA, deleteTeamUser),
    takeLatest(CActions.FETCH_LANGUAGES_SAGA, fetchLanguages),
    takeLatest(CActions.FETCH_API_KEYS_SAGA, fetchApiKeys),
    takeEvery(CActions.CREATE_API_KEY_SAGA, createApiKey),
    takeEvery(CActions.UPDATE_API_KEY_SAGA, updateApiKey),
    takeEvery(CActions.DELETE_API_KEY_SAGA, deleteApiKey),
    takeLatest(CActions.FETCH_QUEUES_SAGA, fetchQueues),
    takeLatest(CActions.FETCH_AGENTS_SAGA, fetchAgents),
    takeEvery(CActions.FETCH_INTEGRATION_ACCESS_SAGA, fetchIntegrationAccess),
    takeEvery(CActions.FETCH_INTEGRATION_USERS_SAGA, fetchIntegrationUsers),
    takeEvery(CActions.UPDATE_INTEGRATION_BINDING_SAGA, updateIntegrationBinding),
    takeEvery(CActions.DELETE_INTEGRATION_BINDING_SAGA, deleteIntegrationBinding),
    takeEvery(CActions.DELETE_INTEGRATION_SAGA, deleteIntegration),
    takeLatest(CActions.FETCH_QUEUE_EVENTS_STATISTICS, fetchQueueEventsStatistics),
    takeLatest(CActions.FETCH_INCOMING_SAGA, fetchIncoming),
    takeLatest(CActions.CREATE_PBX_ADMIN_USERS_SAGA, createPbxAdminUsers),
    takeLatest(CActions.FETCH_USER_PRO_SAGA, fetchProActivation),
    takeLatest(CActions.CREATE_USER_PRO, createProActivation),
    takeLatest(CActions.FETCH_POWER_BROKER_API_KEY, fetchPowerBrokerApiKey),
    takeLatest(CActions.CREATE_POWER_BROKER_API_KEY, createPowerBrokerApiKey),
    takeLatest(CActions.DELETE_POWER_BROKER_API_KEY, deletePowerBrokerApiKey),
    takeLatest(CActions.CREATE_PBX_ADMIN_USERS_SAGA, createPbxAdminUsers),

    takeLatest(
        CActions.FETCH_POLYCOM_DEVICE_CONFIGURATIONS_SAGA,
        fetchDeviceProvisioningConfigurations
    ),
    takeEvery(
        CActions.CREATE_POLYCOM_DEVICE_CONFIGURATION_SAGA,
        createDeviceProvisioningConfiguration
    ),
    takeEvery(
        CActions.UPDATE_POLYCOM_DEVICE_CONFIGURATION_SAGA,
        updateDeviceProvisioningConfiguration
    ),
    takeEvery(
        CActions.DELETE_POLYCOM_DEVICE_CONFIGURATION_SAGA,
        deleteDeviceProvisioningConfiguration
    ),

    takeLatest(
        CActions.FETCH_POLYCOM_CONFIGURATION_TEMPLATES_SAGA,
        fetchProvisioningConfigurationTemplates
    ),
    takeEvery(
        CActions.UPDATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA,
        updateProvisioningConfigurationTemplate
    ),
    takeEvery(
        CActions.DELETE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA,
        deleteProvisioningConfigurationTemplate
    ),
    takeEvery(
        CActions.CREATE_POLYCOM_CONFIGURATION_TEMPLATE_SAGA,
        createProvisioningConfigurationTemplate
    ),
    takeEvery(CActions.CREATE_POLYCOM_RELOAD_SAGA, createProvisioningReload),
    takeLatest(CActions.FETCH_SMS_BINDINGS_USERS_SAGA, fetchSmsBindingsUsers),
    takeLatest(CActions.FETCH_CUSTOMER_PHONE_NUMBERS_SAGA, fetchPhoneNumbers),
    takeLatest(CActions.CREATE_CUSTOMER_PHONE_NUMBER, createCustormerPhoneNumber),
    takeLatest(CActions.UPDATE_CUSTOMER_PHONE_NUMBER, updateCustormerPhoneNumber),
    takeLatest(CActions.DELETE_CUSTOMER_PHONE_NUMBER, deleteCustormerPhoneNumber),
    takeLatest(CActions.FETCH_PROVIDERS_LIST_SAGA, fetchProvidersList),
    takeLatest(CActions.FETCH_CUSTOMER_CDR_PROXY_SAGA, fetchCustomerCDRProxy),
];

export default customerSagas;
