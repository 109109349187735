import Dashboard from 'views/modules/Dashboard.tsx';
import AdminRouter from 'views/admin/AdminRouter';
import {
    faChartPie,
    faCogs,
    faHiking,
    faHistory,
    faServer,
    faStore,
} from '@fortawesome/free-solid-svg-icons';
import CallHistory from '../views/modules/CallHistory';
import UserProfile from '../views/me/UserProfile';
import Journeys from '../views/modules/Journeys/Journeys';
import SuperAdminRouter from '../views/super-admin/SuperAdminRouter';
import Reports from '../views/modules/Reports/Reports';
import License from '../views/license/index';

/// Modules
export const modules = [
    {
        path: '/dashboard',
        exact: true,
        name: 'dashboard',
        icon: faStore,
        has: {
            aPolicyMatching: [{resourceType: 'CALLS'}],
        },
        component: Dashboard,
    },
    {
        path: '/call_history',
        name: 'call_history',
        icon: faHistory,
        has: {
            aPolicyMatching: [{resourceType: 'CALLS'}],
        },
        component: CallHistory,
    },
    {
        path: '/journeys',
        name: 'journeys',
        icon: faHiking,
        has: {
            aPolicyMatching: [{resourceType: 'JOURNEYS'}],
        },
        component: Journeys,
    },
    {
        path: '/reports',
        title: 'Reports',
        name: 'reports',
        icon: faChartPie,
        component: Reports,
        has: {
            aPolicyMatching: [{resourceType: 'QUEUE_EVENTS'}],
        },
    },
];

export const administration = [
    {
        path: '/admin',
        name: 'settings',
        icon: faCogs,
        has: {
            aPolicyMatching: [
                {resourceType: 'USERS'},
                {resourceType: 'TEAMS'},
                {resourceType: 'POLICIES'},
                {resourceType: 'FAXES'},
                {resourceType: 'SMS'},
                {resourceType: 'EXTENSIONS'},
                {resourceType: 'INTEGRATIONS'},
                {resourceType: 'API_KEYS'},
            ],
        },
        component: AdminRouter,
    },
];

export const superAdministration = [
    {
        path: '/super_admin',
        name: 'super_admin',
        icon: faServer,
        has: {
            oneOfTheseRoles: ['super administrator', 'customer service agent'],
        },
        component: SuperAdminRouter,
    },
];

/// User
export const user = [
    {
        path: '/profile/me',
        exact: true,
        name: 'my-profile',
        component: UserProfile,
        has: {
            forbidTravel: true,
        },
    },
];

export const customer = [
    {
        path: '/license',
        title: 'License',
        name: 'license',
        component: License,
    },
];

const routes = [...modules, ...administration, ...user, ...superAdministration, ...customer];

export default routes;
