import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {getPortalAudioOwner, getPortalAudioShow} from '../../store/selectors/portal-selectors';
import {customerExtensionsSelector} from '../../store/selectors/customer-selectors';
import {getUserCustomerLicense, getUserLocale} from '../../store/selectors/user-selectors';
import {
    deleteRecording,
    fetchCallParticipants,
    fetchRecordingLink,
} from '../../store/actions/cdr-action';
import {hideAudio, secondaryToast, showAudio, storeAudio} from '../../store/actions/portal-action';
import {getCallsParticipants} from '../../store/selectors/cdr-selectors';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    PopoverBody,
    Table,
    UncontrolledPopover,
    UncontrolledTooltip,
} from 'reactstrap';
import {
    formatAlphanumericString,
    formatAmericanNumber,
    formatDateToHour,
    formatSecondsToHour,
} from '../../utils/format';
import {copyToClipboard} from '../../utils/clipboard';
import {forceDownload} from '../../utils';
import {
    call_color,
    call_description,
    call_icon,
    extension_label,
    formatCallRecordingFilename,
} from '../../utils/call_history';
import {Has} from '../security/Has';
import LightFontAwesomeIcon from '../utils/LightFontAwesomeIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWifi, faHourglassHalf, faPhone} from '@fortawesome/free-solid-svg-icons';
import {Action, ActionsCell} from '../layout/Table/Cells';
import Spinner from '../spinner/Spinner';

export const StatusCell = ({call, statusFilterDispatch, directionFilterDispatch}) => {
    const intl = useIntl();

    return (
        <>
            <span
                id={`call-${formatAlphanumericString(call.id)}-status-tooltip`}
                className={'ml-2 cursor-pointer'}
                onClick={() => {
                    statusFilterDispatch(call.status);
                    directionFilterDispatch(call.direction);
                }}
            >
                <LightFontAwesomeIcon
                    icon={call_icon(call)}
                    className={`text-${call_color(call)}`}
                />
            </span>
            <UncontrolledTooltip
                target={`call-${formatAlphanumericString(call.id)}-status-tooltip`}
                placement={'right'}
            >
                {call_description(call, intl)}
            </UncontrolledTooltip>
        </>
    );
};

StatusCell.defaultProps = {
    call: {},
    statusFilterDispatch: () => {},
    directionFilterDispatch: () => {},
};

export const NameCell = ({call, nameFilterDispatch}) =>
    call.name && (
        <span className={'cursor-pointer'} onClick={() => nameFilterDispatch(call.name)}>
            {call.name}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );

export const NumberCell = ({call, numberFilterDispatch}) =>
    call.number && (
        <span className={'cursor-pointer'} onClick={() => numberFilterDispatch(call.number)}>
            {formatAmericanNumber(call.number)}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );

NumberCell.defaultProps = {
    numberFilterDispatch: () => {},
};

export const UserCell = ({call, extensionFilterDispatch}) => {
    const extensions = useSelector(customerExtensionsSelector);
    return (
        <span className={'cursor-pointer'} onClick={() => extensionFilterDispatch(call.extension)}>
            {extension_label(call.extension, extensions, undefined) || (
                <FormattedMessage id={'message.system.extension'} defaultMessage={'System'} />
            )}
            <LightFontAwesomeIcon className={'text-primary ml-1'} icon={'filter'} size={'xs'} />
        </span>
    );
};

UserCell.defaultProps = {
    extensionFilterDispatch: () => {},
};

export const CallHistoryActionsCell = ({call}) => {
    const audioOwner = useSelector(getPortalAudioOwner);
    const showingAudio = useSelector(getPortalAudioShow);
    const reduxDispatch = useDispatch();

    useEffect(() => {
        setPlaying(audioOwner === call.id);
    }, [audioOwner, call.id]);

    const [playing, setPlaying] = useState(false);

    const shareLink = `${window.location.origin}/call_history?id=${call.id}`;

    const playToggle = useCallback(() => {
        if (!playing) {
            reduxDispatch(
                fetchRecordingLink({
                    id: call.id,
                    saveAs: formatCallRecordingFilename(call),
                    callback: (url) => {
                        return reduxDispatch(
                            storeAudio({
                                owner: call.id,
                                autoPlay: true,
                                src: url,
                                type: 'audio/mp3',
                            })
                        );
                    },
                })
            );
            if (audioOwner !== call.id) {
                reduxDispatch(hideAudio());
            }
            reduxDispatch(showAudio());
        } else {
            reduxDispatch(hideAudio());
            reduxDispatch(deleteRecording(call.id));
        }
    }, [playing, reduxDispatch, call, audioOwner]);

    const download = useCallback(() => {
        reduxDispatch(
            fetchRecordingLink({
                id: call.id,
                saveAs: formatCallRecordingFilename(call),
                callback: (url) => {
                    typeof url === 'string'
                        ? forceDownload(url)
                        : url.forEach((u, i) => {
                              setTimeout(() => {
                                  forceDownload(u, `file-${i}`);
                              }, 1000 * i);
                          });
                },
            })
        );
    }, [reduxDispatch, call]);

    return (
        <ActionsCell>
            {
                <Has loaded={`recording_${call.id}`} requiresTravel={false}>
                    <Action
                        id={`player-${call.id.replace('.', '')}`}
                        enabled={call && call.recorded}
                        onClick={call && call.recorded ? playToggle : () => {}}
                        icon={playing && showingAudio ? 'stop' : 'play'}
                    />
                </Has>
            }
            {
                <Has
                    loaded={`recording_${call.id}`}
                    requiresTravel={false}
                    orElse={
                        <Spinner
                            color={'primary'}
                            background={'transparent'}
                            global={false}
                            size={20}
                            text={false}
                        />
                    }
                >
                    <Action
                        className={'ml-2'}
                        enabled={call && call.recorded}
                        icon={'download'}
                        onClick={call && call.recorded ? download : () => {}}
                    />
                </Has>
            }
            <Action
                icon={'copy'}
                onClick={() => {
                    copyToClipboard(shareLink);
                    reduxDispatch(secondaryToast({content: 'Link copied to clipboard'}));
                }}
            />
        </ActionsCell>
    );
};

CallHistoryActionsCell.defaultProps = {
    call: {},
};

export const TagsCell = ({call, tagFilterDispatch, activeTags}) => {
    const [toggle, setToggle] = useState(false);
    const customerLicense = useSelector(getUserCustomerLicense);
    // The licence is checked and if not authorised,
    // a message is displayed to say that the user does not have access with their licence.
    // We also disable the button action.
    const restriction = customerLicense === 'PREMIUM' || customerLicense === 'PRO';

    const firstTags = activeTags ? activeTags.filter((t) => call.tags.includes(t)) : [];
    const tags = call.tags
        ? [...firstTags.sort(), ...call.tags.filter((t) => !firstTags.includes(t)).sort()]
        : [];

    return (
        <div className={'d-flex mt--1'} style={{overflow: 'visible'}}>
            {tags.map((tag, index) => {
                const tooltipId = formatAlphanumericString(`tag-${tag}-${call.id}`);
                switch (index) {
                    case 0:
                    case 1:
                        return (
                            <Button
                                className={`${
                                    typeof tagFilterDispatch === 'function' ? '' : 'cursor-default'
                                } mt-1`}
                                color={'darker'}
                                onClick={(e) => {
                                    if (tagFilterDispatch && !restriction) tagFilterDispatch(tag);
                                    e.preventDefault();
                                }}
                                outline
                                size={'sm'}
                                id={tooltipId}
                                key={tooltipId}
                            >
                                <LightFontAwesomeIcon icon={'tag'} className={'mr-1'} />
                                {tag}
                                {restriction && (
                                    <UncontrolledTooltip placement={'top'} target={tooltipId}>
                                        <FormattedMessage
                                            id={'tooltip.tags.warning'}
                                            defaultMessage={'Search by labels not possible'}
                                        />
                                    </UncontrolledTooltip>
                                )}
                            </Button>
                        );
                    case 2:
                        return (
                            <Dropdown key={index} isOpen={toggle} toggle={() => setToggle(!toggle)}>
                                <DropdownToggle
                                    className={'mt-1'}
                                    color={'darker'}
                                    outline
                                    size={'sm'}
                                >
                                    <LightFontAwesomeIcon icon={'ellipsis-h'} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {tags.slice(2).map((t, i) => (
                                        <DropdownItem
                                            key={i}
                                            onClick={(e) => {
                                                if (tagFilterDispatch) tagFilterDispatch(tag);
                                                e.preventDefault();
                                            }}
                                        >
                                            {t}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};

TagsCell.defaultProps = {
    call: {},
};

export const ParticipantHistoryCell = ({callId}) => {
    const [callHistory, setCallHistory] = useState([]);
    const extensions = useSelector(customerExtensionsSelector);
    const locale = useSelector(getUserLocale);
    const callParticipants = useSelector(getCallsParticipants);

    const statusAction = (action) => {
        switch (action) {
            case 'waiting':
                return {
                    icon: faHourglassHalf,
                    rotate: 0,
                    class: 'offset-md-4',
                };
            case 'connected':
                return {
                    icon: faPhone,
                    rotate: 0,
                    class: 'text-success offset-md-4',
                };
            case 'hanged':
            case 'not connected':
            case 'voicemail':
            case 'missed':
                return {
                    icon: faPhone,
                    rotate: 135,
                    class: 'text-danger offset-md-4',
                };
            default:
                return {
                    icon: faPhone,
                    rotate: 0,
                    class: 'offset-md-4',
                };
        }
    };

    const ringCall = () => (
        <td className={'d-flex flex-column'}>
            <FontAwesomeIcon icon={faWifi} />
            <FontAwesomeIcon icon={faPhone} transform={{rotate: 135}} style={{marginTop: '-6px'}} />
        </td>
    );

    const calculationDurationCall = (index) => {
        if (callParticipants[callId][index + 1] !== undefined) {
            const firstDuration = callParticipants[callId][index + 1]._source.duration;
            const secondeDuration = callParticipants[callId][index]._source.duration;
            return formatSecondsToHour(firstDuration - secondeDuration);
        } else if (callParticipants[callId].length === 1) {
            return formatSecondsToHour(callParticipants[callId][index]._source.duration);
        }
    };

    const reduxDispatch = useDispatch();

    const getParticipantHistory = useCallback(() => {
        if (callParticipants[callId] === undefined) reduxDispatch(fetchCallParticipants({callId}));
    }, [callId, callParticipants, reduxDispatch]);

    useEffect(() => {
        if (
            !!callParticipants[callId] &&
            callParticipants.hasOwnProperty(callId) &&
            callHistory.length === 0
        ) {
            const firstCall = callParticipants[callId][0];

            if (
                firstCall &&
                firstCall._source.action === 'ring' &&
                firstCall._source.duration > 0
            ) {
                setCallHistory((oldArray) => [
                    ...oldArray,
                    {
                        id: firstCall._source.unique_id,
                        extension: '',
                        duration: formatSecondsToHour(firstCall._source.duration),
                        action: 'waiting',
                        action_time: firstCall._source.call_start_time,
                    },
                ]);
            }

            for (let [index, call] of callParticipants[callId].entries()) {
                setCallHistory((oldArray) => [
                    ...oldArray,
                    {
                        id: call._source.unique_id,
                        extension: extension_label(call._source.extension, extensions),
                        duration: calculationDurationCall(index),
                        action: call._source.action,
                        action_time: call._source.action_time,
                    },
                ]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callParticipants]);

    return (
        <>
            <button
                id={`participants-${formatAlphanumericString(callId)}`}
                className='p-0'
                style={{background: 'none', border: 'none'}}
            >
                <LightFontAwesomeIcon
                    className='text-primary ml-1 cursor-pointer'
                    icon={'info-circle'}
                    size={'lg'}
                    id={`participants-${formatAlphanumericString(callId)}`}
                    onClick={() => {
                        getParticipantHistory();
                    }}
                />
            </button>
            <UncontrolledPopover
                trigger='focus'
                placement='auto-start'
                target={`participants-${formatAlphanumericString(callId)}`}
            >
                <PopoverBody className='croo-table-cell-history' style={{width: '500px'}}>
                    <Table>
                        <thead>
                            <tr>
                                <th />
                                <th>
                                    <FormattedMessage
                                        id={'filter.title.hour'}
                                        defaultMessage={'Hour'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'filter.title.extension'}
                                        defaultMessage={'Extension'}
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        id={'message.duration'}
                                        defaultMessage={'Duration'}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {callHistory.length === 0 ? (
                                <tr>
                                    <td>
                                        <Spinner
                                            color={'primary'}
                                            background={'transparent'}
                                            global={false}
                                            size={20}
                                            centered
                                        />
                                    </td>
                                </tr>
                            ) : (
                                callHistory.map((callEvent) => (
                                    <tr
                                        key={`participants-${formatAlphanumericString(
                                            callEvent.action_time + callEvent.extension
                                        )}`}
                                    >
                                        {callEvent.action === 'ring' ? (
                                            ringCall()
                                        ) : (
                                            <td>
                                                <FontAwesomeIcon
                                                    transform={{
                                                        rotate: statusAction(callEvent.action)
                                                            .rotate,
                                                    }}
                                                    icon={statusAction(callEvent.action).icon}
                                                    id={`call-${formatAlphanumericString(
                                                        callId
                                                    )}-status-tooltip`}
                                                    className={statusAction(callEvent.action).class}
                                                />
                                            </td>
                                        )}
                                        <td>{formatDateToHour(callEvent.action_time, locale)}</td>
                                        <td>
                                            {callEvent.action !== 'waiting' ? (
                                                callEvent.extension
                                            ) : (
                                                <FormattedMessage
                                                    id={'message.reports.hold.time'}
                                                    defaultMessage={'Hold time'}
                                                />
                                            )}
                                        </td>
                                        <td>{callEvent.duration}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </PopoverBody>
            </UncontrolledPopover>
        </>
    );
};
